import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './stake.module.scss';
import { TypeAnimation } from 'react-type-animation';
import { useAccount } from 'wagmi';
import { BrowserProvider, ethers, Contract } from 'ethers';
import { parseUnits } from 'viem';
import { stakingContractAbi } from '../../constants/stakingContractAbi';
import StakeModal from '../../components/stakeModal/stakeModal';
import { stakingContractAddress } from '../../constants/contants';
import { formatter } from '../../helpers/helpers';

class UserInfo {
  points: string = '01';
}

class UserStake {
  amount: number[] = [];
  startTime: number[] = [];
  poolId: number[] = [];
  withdrawnStatuse: boolean[] = [];
  isUnlocked: boolean[] = [];
  unlockDate: number[] = [];
}

const Stake = () => {
  const { address, isConnected } = useAccount();
  const [amount, setAmount] = useState<number>(0);
  const [contract, setContract] = useState<Contract | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const [showModal, setShowModal] = useState(false);
  const [poolIndex, setPoolIndex] = useState(0);
  const [lockDuration, setLockDuration] = useState(0);
  const [userStakes, setUserStakes] = useState<UserStake[]>();

  const handleShow = (poolIndex: number, lockDuration: number) => {
    setShowModal(true);
    setPoolIndex(poolIndex);
    setLockDuration(lockDuration);
  };
  const handleClose = () => setShowModal(false);

  const formatStakes = (data: {
    amounts: bigint[];
    startTimes: bigint[];
    poolIds: bigint[];
    withdrawnStatuses: boolean[];
    isUnlocked: boolean[];
    unlockDates: bigint[];
  }) =>
    data.amounts.map((amount, index) => {
      return {
        amount: amount.toString(), // Convert BigInt to string
        startTime: data.startTimes[index].toString(),
        poolId: data.poolIds[index].toString(),
        withdrawn: data.withdrawnStatuses[index],
        isUnlocked: data.isUnlocked[index],
        unlockDate: data.unlockDates[index].toString(),
      };
    });

  useEffect(() => {
    const initContract = async () => {
      try {
        if (isConnected && address) {
          const provider = new BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const contractInstance = new ethers.Contract(stakingContractAddress, stakingContractAbi, signer);

          setContract(contractInstance);
          const getTotalPoints = await contractInstance.getTotalPoints(signer.address);
          setUserInfo({ points: ethers.formatUnits(getTotalPoints, 18) });

          const getUserStakes = await contractInstance.getAllUserStakes(signer.address);

          const formattedUserStakes = JSON.parse(JSON.stringify(formatStakes(getUserStakes)));

          setUserStakes(formattedUserStakes);
        }
      } catch (error) {
        console.log('*****');
        console.log(error);
        console.log('*****');
      }
    };
    initContract();
  }, [isConnected, address, showModal]);

  return (
    <>
      <StakeModal show={showModal} handleClose={handleClose} poolIndex={poolIndex} lockDuration={lockDuration} />
      <div className="section lead-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Stake OAI to Earn Points</h2>
              <p>
                Stake earlier to earn more points. Points will determine your share of yield generated by Olex projects.{' '}
                <a href="https://olex-ai.gitbook.io/olex/points-system-documentation" target="_blank">
                  Learn more about the points system here
                </a>
                .
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col text-end">
              <strong>Your points: {formatter.format(parseFloat(userInfo?.points || '0'))}</strong>
            </div>
          </div>
          {userStakes && userStakes[0].amount && (
            <div className="row">
              <div className="col">
                <h4 className="mt-3">Your Positions</h4>
                <div className="pools-header d-none d-md-block">
                  <div className="row">
                    <div className="col-md-3">Stake Token</div>
                    <div className="col-md-3">Amount Locked</div>
                    <div className="col-md-3">Unlock Date</div>
                    <div className="col-md-3"></div>
                  </div>
                </div>
                {userStakes &&
                  userStakes[0].amount &&
                  userStakes.map((stake, index) => (
                    <div key={`staked-pool-${index}`} className="pool-container">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-md-3">
                          <img width="40px" style={{ marginRight: '10px', marginTop: '-5px' }} src="/oai.svg" />
                          OAI
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                          <strong className="d-md-none">Amount Locked: </strong>
                          {ethers.formatUnits(stake.amount ? stake.amount.toString() : 0, 18)}
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                          <strong className="d-md-none">Unlock Date: </strong>
                          {new Date(Number(stake.unlockDate) * 1000).toLocaleString()}
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                          <button className="btn btn-primary me-2 disabled" style={{ width: '100%' }}>
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <h4 className="mt-3">Available Pools</h4>
              <div className="pools-header d-none d-md-block">
                <div className="row">
                  <div className="col-md-3">Stake Token</div>
                  <div className="col-md-3">Lock Period</div>
                  <div className="col-md-3">Points Multiplier</div>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="pool-container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-3">
                    <img width="40px" style={{ marginRight: '10px', marginTop: '-5px' }} src="/oai.svg" />
                    OAI
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <strong className="d-md-none">Lock Period: </strong>30 days
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <strong className="d-md-none">Multiplier: </strong>2x
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <button
                      className="btn btn-primary me-2"
                      style={{ width: '100%' }}
                      onClick={() => handleShow(0, 30)}
                    >
                      Stake
                    </button>
                  </div>
                </div>
              </div>
              <div className="pool-container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-3">
                    <img width="40px" style={{ marginRight: '10px', marginTop: '-5px' }} src="/oai.svg" />
                    OAI
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <strong className="d-md-none">Lock Period: </strong>90 days
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <strong className="d-md-none">Multiplier: </strong>3x
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <button
                      className="btn btn-primary me-2"
                      style={{ width: '100%' }}
                      onClick={() => handleShow(1, 90)}
                    >
                      Stake
                    </button>
                  </div>
                </div>
              </div>
              <div className="pool-container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-3">
                    <img width="40px" style={{ marginRight: '10px', marginTop: '-5px' }} src="/oai.svg" />
                    OAI
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <strong className="d-md-none">Lock Period: </strong>180 days
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <strong className="d-md-none">Multiplier: </strong>4x
                  </div>
                  <div className="col-md-3 mt-2 mt-md-0">
                    <button
                      className="btn btn-primary me-2"
                      style={{ width: '100%' }}
                      onClick={() => handleShow(2, 180)}
                    >
                      Stake
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stake;
