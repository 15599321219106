import React, { FC, useEffect, useState } from 'react';
import styles from './stakeModal.module.scss';
import { Modal, Button, Form, Toast, ToastContainer, InputGroup, Table } from 'react-bootstrap';
import { oaiTokenAddress, stakingContractAddress } from '../../constants/contants';
import { BrowserProvider, Contract, ethers } from 'ethers';
import { stakingContractAbi } from '../../constants/stakingContractAbi';
import { useAccount } from 'wagmi';
import { tokenAbi } from '../../constants/tokenAbi';
import { addDays, formatter } from '../../helpers/helpers';

interface StakeModalProps {
  show: boolean;
  handleClose: () => void;
  poolIndex: number;
  lockDuration: number;
}

const StakeModal: FC<StakeModalProps> = ({ show, handleClose, poolIndex, lockDuration }) => {
  const [tokenBalance, setTokenBalance] = useState('0');
  const [isApproved, setIsApproved] = useState(false);
  const [tokenContract, setTokenContract] = useState<Contract | null>(null);
  const { address, isConnected } = useAccount();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [formData, setFormData] = useState({
    tokenLockAmount: 0,
  });

  const today = new Date();

  useEffect(() => {
    const initContract = async () => {
      if (isConnected && show) {
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contractInstance = new ethers.Contract(oaiTokenAddress, tokenAbi, signer);
        setTokenContract(contractInstance);
        const getBalance = await contractInstance.balanceOf(await signer.getAddress());
        setTokenBalance(ethers.formatUnits(getBalance, 18));
      }
    };
    initContract();
  }, [isConnected, show, oaiTokenAddress, tokenAbi]);

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleApprove = async () => {
    const amountInWei = ethers.parseUnits(String(formData.tokenLockAmount), 18);

    await tokenContract!.approve(stakingContractAddress, amountInWei);
    setIsApproved(true);
    setToastMessage('Approval Successful');
    setShowToast(true);
  };

  const handleStake = async () => {
    const provider = new BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const stakingContract = new ethers.Contract(stakingContractAddress, stakingContractAbi, signer);

    const amountInWei = ethers.parseUnits(String(formData.tokenLockAmount), 18);

    await stakingContract.stake(amountInWei, poolIndex);
    setToastMessage('Tokens Staked');
    setShowToast(true);
    handleClose();
  };

  const handleMax = () => {
    const maxAmount = parseFloat(tokenBalance);
    setFormData((prev) => ({
      ...prev,
      tokenLockAmount: maxAmount,
    }));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Stake OAI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Stake tokens to earn points and generate yield from future Olex projects.</p>
          <p>
            <a
              href="https://app.uniswap.org/explore/tokens/base/0x63c387f45046bc3c4ad8fe12fe9cfa2a7b305385"
              target="_blank"
            >
              Get OAI
            </a>
          </p>
          <Form.Group>
            <InputGroup>
              <Form.Control
                type="number"
                value={formData.tokenLockAmount}
                name="tokenLockAmount"
                onChange={handleSelectionChange}
                size="lg"
                style={{
                  borderRadius: '0.5rem',
                }}
              />
              <Button
                variant="primary"
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  height: '75%',
                  padding: '0 10px',
                }}
                onClick={handleMax}
              >
                MAX
              </Button>
            </InputGroup>
          </Form.Group>
          <strong>Balance: {formatter.format(parseFloat(tokenBalance))}</strong>
          <Table className="mt-2" variant="striped">
            <tbody>
              <tr>
                <td>Stake amount</td>
                <td>{formatter.format(formData.tokenLockAmount)} OAI</td>
              </tr>
              <tr>
                <td>Lock Length</td>
                <td>{lockDuration} days</td>
              </tr>
              <tr>
                <td>Unlock Date</td>
                <td>{addDays(today.toString(), lockDuration)}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {!isApproved ? (
            <Button variant="primary" style={{ width: '100%' }} onClick={handleApprove}>
              Approve
            </Button>
          ) : (
            <Button variant="primary" style={{ width: '100%' }} onClick={handleStake}>
              Stake
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ToastContainer position="top-end" className="p-3">
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default StakeModal;
