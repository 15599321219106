import React, { FC, useEffect, useState } from 'react';
import styles from './home.module.scss';
import { TypeAnimation } from 'react-type-animation';
import HomeNews from '../../components/home/news';
import HomeTasks from '../../components/home/tasks';
import HomeProducts from '../../components/home/products';
import HomeHowItWorks from '../../components/home/how-it-works';
import HomeRoadmap from '../../components/home/roadmap';
import HomePowered from '../../components/home/powered';

interface HomeProps {}

const Home = () => {
  const [sectionOne, setSectionOne] = useState('news');

  const leadSectionComponents: { [key: string]: JSX.Element | null } = {
    news: <HomeNews />,
    tasks: <HomeTasks />,
    products: <HomeProducts />,
  };

  return (
    <>
      <div className="section lead-section lead-bg">
        <div className="container">
          <div className="row mt-4 mt-md-5">
            <div className="col-md-6 mt-md-5 mb-4">
              <h2>Hi, I'm Olex.</h2>
              <h1 className="mb-5 mb-md-3">The First 100% AI Driven DeFi Project</h1>
              <span className="tagline mt-5">
                I'm powering the future of crypto using advanced AI to become the worlds first 100% autonomous DeFi
                project.
              </span>
              <br />
              <a
                href="https://app.uniswap.org/swap?&chain=base&outputCurrency=0x63c387F45046bc3C4aD8Fe12fe9cfa2A7b305385"
                target="_blank"
              >
                <button className="mt-4 me-3 btn btn-primary">Buy OLEX</button>
              </a>
              <a href="/stake">
                <button className="mt-4 btn btn-primary">Stake OLEX</button>
              </a>
            </div>
            <div className="col-6 text-center"></div>
          </div>
          <div className="row figures justify-content-center">
            <div className="col-6 col-md-3 figures-tile d-flex flex-column flex-md-row justify-content-center align-items-center">
              <h3 className="mb-0">900+</h3>
              <span className="ml-3 text-center text-md-start">
                Data
                <br />
                Points
              </span>
            </div>
            <div className="col-6 col-md-3 figures-tile d-flex flex-column flex-md-row justify-content-center align-items-center">
              <h3 className="mb-0">32</h3>
              <span className="ml-3 text-center text-md-start">
                Active
                <br />
                Worker Bots
              </span>
            </div>
            <div className="col-6 col-md-3 mt-4 mt-md-0 figures-tile d-flex flex-column flex-md-row justify-content-center align-items-center">
              <h3 className="mb-0">700+</h3>
              <span className="ml-3 text-center text-md-start">
                Trained
                <br />
                Hours
              </span>
            </div>
            <div className="col-6 col-md-3 mt-4 mt-md-0 figures-tile d-flex flex-column flex-md-row justify-content-center align-items-center">
              <h3 className="mb-0">61</h3>
              <span className="ml-3 text-center text-md-start">
                News
                <br />
                Sources
              </span>
            </div>
          </div>
          <div className="row mt-xl">
            <div className="col-md-6">
              <h2 className="thin large">Fueled by the market, powered by AI.</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ul className="left-nav mt-5">
                <li className={sectionOne === 'news' ? 'active' : ''} onClick={() => setSectionOne('news')}>
                  News
                  {sectionOne === 'news' && <i className="fa-solid fa-arrow-right"></i>}
                </li>
                <li className={sectionOne === 'tasks' ? 'active' : ''} onClick={() => setSectionOne('tasks')}>
                  Task Development
                  {sectionOne === 'tasks' && <i className="fa-solid fa-arrow-right"></i>}
                </li>
                <li className={sectionOne === 'products' ? 'active' : ''} onClick={() => setSectionOne('products')}>
                  Product & Community Nurturing
                  {sectionOne === 'products' && <i className="fa-solid fa-arrow-right"></i>}
                </li>
              </ul>
            </div>
            <div className="col-md-6">{leadSectionComponents[sectionOne] || null}</div>
          </div>
        </div>
      </div>
      <div id="howitworks" className="section">
        <div className="container">
          <HomeHowItWorks />
        </div>
      </div>
      <div id="roadmap" className="section section-colored">
        <div className="container">
          <HomeRoadmap />
        </div>
      </div>
      <div id="poweredbyoai" className="section">
        <div className="container">
          <HomePowered />
        </div>
      </div>
    </>
  );
};

export default Home;
