import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Stake from './views/stake/stake';
import Footer from './components/footer/footer';
import Header from './components/header/header';

import ReactGA from 'react-ga';
import Home from './views/home/home';
import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { base } from 'wagmi/chains';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

ReactGA.initialize('G-XRECWKH3EN');
ReactGA.pageview(window.location.pathname + window.location.search);

const config = getDefaultConfig({
  appName: 'OlexAI',
  projectId: 'ea685af62d710bde5737402f505d7048',
  chains: [base],
  ssr: false,
});
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Header />

          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/stake" element={<Stake />} />
          </Routes>

          <Footer />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
